@keyframes rotationClock {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.rotationClock {
  animation: rotationClock 5s infinite linear;
}

.rotation {
  animation: rotation 5s infinite linear;
}
