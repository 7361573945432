@font-face {
    font-family: 'Lato Light';
    src: local('Lato Light'), local('Lato Light'),
    url('lato-300.woff') format('woff'),
    url('lato-300.woff2') format('woff2');
    font-display: block;
}

@font-face {
    font-family: 'Lato Regular';
    src: local('Lato Regular'), local('Lato Regular'),
    url('lato-regular.woff') format('woff'),
    url('lato-regular.woff2') format('woff2');
    font-display: block;
}

@font-face {
    font-family: 'Lato Bold';
    src: local('Lato Bold'), local('Lato Bold'),
    url('lato-700.woff') format('woff'),
    url('lato-700.woff2') format('woff2');
    font-display: block;
}

@font-face {
    font-family: 'Montserrat Bold';
    src: local('Montserrat Bold'), local('Montserrat Bold'),
    url('montserrat-800.woff') format('woff'),
    url('montserrat-800.woff2') format('woff2');
    font-display: block;
}

@font-face {
    font-family: 'Roboto Black';
    src: local('Roboto Black'), local('Roboto Black'),
    url('Roboto-Black.ttf') format('ttf');
    font-display: block;
}

@font-face {
    font-family: 'Roboto Bold';
    src: local('Roboto Bold'), local('Roboto Bold'),
    url('Roboto-Bold.ttf') format('ttf');
    font-display: block;
}

@font-face {
    font-family: 'Roboto Light';
    src: local('Roboto Light'), local('Roboto Light'),
    url('Roboto-Light.ttf') format('ttf');
    font-display: block;
}

@font-face {
    font-family: 'Roboto Medium';
    src: local('Roboto Medium'), local('Roboto Medium'),
    url('Roboto-Medium.ttf') format('ttf');
    font-display: block;
}

@font-face {
    font-family: 'Roboto Regular';
    src: local('Roboto Regular'), local('Roboto Regular'),
    url('Roboto-Regular.ttf') format('ttf');
    font-display: block;
}

@font-face {
    font-family: 'Roboto Thin';
    src: local('Roboto Thin'), local('Roboto Thin'),
    url('Roboto-Thin.ttf') format('ttf');
    font-display: block;
}
