.flipCard {
  height: 300px;
  border: 1px solid #474648;
  border-radius: 8px;
  gap: 8px;
}

.front {
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  width: 100%;
  cursor: "pointer";
}

.back {
  padding: 18px;
  display: flex;
  height: 100%;
  justify-content: center;
  justify-content: space-between;
  align-items: "left";
  cursor: "pointer";
}
