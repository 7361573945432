
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.hideScrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.hideScrollbar::-webkit-scrollbar {
    display: none;
}

::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar-thumb {
    background: rgba(150, 150, 150, 1);
    border-radius: 25px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

.MuiTabs-scrollButtons.Mui-disabled {
	opacity: 0.3;
}
.MuiTabs-indicator{
	opacity: 0.05
}
#button-collapse {
    transition: width 0.2s
}
#search-collapse {
    transition: width 0.2s
}
#scrollable-content::-webkit-scrollbar {
  display: none;
}