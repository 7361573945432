.card {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 20;
  background-color: #1b1b1d;
}

.card::before {
  content: "";
  position: absolute;
  top: var(--y);
  left: var(--x);
  transform: translate(-50%, -50%);
  background: radial-gradient(#d775ff, transparent, transparent);
  width: 300px;
  height: 300px;
  opacity: 0;
  transition: opacity 0.5s;
  z-index: 22;
}

.card:hover::before {
  opacity: 0.4;
}

.card::after {
  content: "";
  position: absolute;
  inset: 2px;
  border-radius: 18px;
  background: transparent;
}

.cardAbove {
  z-index: 10;
  background: 1b1b1d;
}

.node-button {
  border-radius: 100px;
  padding: 8px 24px;
}